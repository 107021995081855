import React from 'react';
import { Box, Container, Typography, Grid, CardMedia, Stack, Card } from '@mui/material';
import { ProjectsData } from '@src/shared/constants/Projects';
import { noImage } from '@src/shared/constants/Images';
import {NavbarSpace} from "@components/Utilities/Navbar";

const ProjectsSection: React.FunctionComponent = () => {
    return (
        <Container id="#projects">
            <Stack alignItems='center' spacing={8}>
                <NavbarSpace />
                <Typography
                    fontWeight={600}
                    variant='h2'
                    color='gray'
                >
                    Projects
                </Typography>
                {ProjectsData.map((project, indexProject) => (
                    <Grid
                        container
                        justifyContent="center"
                        textAlign="center"
                        key={indexProject}
                    >
                        <Typography
                            fontWeight={600}
                            sx={{
                                fontSize: { xs: 30, md: 45 }
                            }}
                        >
                            {project.title}
                        </Typography>
                        <Grid container my={4} spacing={2}>
                            {project.data.map((project, index) => (
                                <Grid item xs={12} md={6} key={index}>
                                    <Box component={Card} boxShadow={8} borderRadius={5}>
                                        <CardMedia
                                            component="img"
                                            image={project.image ? project.image : noImage}
                                            alt={project.title}
                                            height="350"
                                        />
                                        <Stack p={2} spacing={1}>
                                            <Typography
                                                fontWeight="bold"
                                                sx={{
                                                    fontSize: { xs: 18, md: 25 }
                                                }}
                                            >
                                                {project.title}
                                            </Typography>
                                            <Typography>Location: {project.location}</Typography>
                                            <Typography>Units: {project.units}</Typography>
                                            <Typography fontWeight={600}>{project.status}</Typography>
                                        </Stack>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                ))}
            </Stack>
        </Container>
    );
};
export default ProjectsSection;
