import React from 'react';
import { Box, CardMedia, Container, Grid, Stack, Typography, Divider, Card } from '@mui/material';
import RoundedButton from '@components/Utilities/Buttons/RoundedButton';
import { COLORS } from '@colors/index';
import { NavLink } from 'react-router-dom';
import {NavbarSpace} from "@components/Utilities/Navbar";

const InfoSection: React.FunctionComponent = () => {
    return (
        <Container>
            <NavbarSpace />
            <Stack spacing={8}>
                <Box>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        sx={{
                            height: '80vh',
                            backgroundImage: 'url(/img/home/terra.png)',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: { xs: 'contain', md: 0 },
                            backgroundPosition: 'center'
                        }}
                    >
                        <Grid item xs={12} md={5}>
                            <Grid
                                container
                                bgcolor="rgba(255, 255, 255, 0.8)"
                                borderRadius={5}
                                p={4}
                                boxShadow={4}
                                sx={{
                                    justifyContent: { xs: 'center', md: 'left' },
                                    rowGap: 2
                                }}
                            >
                                <Grid item>
                                    <Typography
                                        fontWeight={500}
                                        sx={{
                                            fontSize: { xs: 30, md: 40 },
                                            textAlign: { xs: 'center', md: 'left' }
                                        }}
                                    >
                                        Solution-Based Development
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        fontWeight={500}
                                        sx={{
                                            fontSize: { xs: 14, md: 18 },
                                            textAlign: { xs: 'center', md: 'left' }
                                        }}
                                    >
                                        Blue Terra Development (BTD) provides investment opportunities in
                                        three major categories: RV parks, self-storage, and multi-family. BTD will also
                                        consider select development opportunities, such as office and single-family housing.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            md={1}
                            sx={{
                                display: { xs: 'none', md: 'inline' }
                            }}
                        >
                            <Divider color={COLORS.Blue} sx={{ borderBottomWidth: 2 }} />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            sx={{
                                display: { xs: 'none', md: 'inline' }
                            }}
                        >
                            <CardMedia component="img" image="/img/home/terra.png" alt="map" />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={8} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <CardMedia
                                sx={{ borderRadius: 10 }}
                                component="img"
                                image="/img/home/significant.jpg"
                                alt="significant"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack
                                alignItems="center"
                                textAlign="center"
                                spacing={2}
                                bgcolor="rgba(255, 255, 255, 0.8)"
                                borderRadius={5}
                                p={4}
                                boxShadow={4}
                            >
                                <Typography
                                    fontWeight={500}
                                    sx={{
                                        fontSize: { xs: 25, md: 35 }
                                    }}
                                >
                                    Significant opportunities with manageable risk
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: { xs: 14, md: 18 }
                                    }}
                                >
                                    Due to our extensive network, BTD pursues real estate opportunities in greater Boise,
                                    Idaho and select out-of-state areas (AZ, OR, UT).
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container justifyContent="center" textAlign="center">
                        <Typography
                            fontWeight={500}
                            sx={{
                                fontSize: { xs: 25, md: 35 }
                            }}
                        >
                            Quality & Professional Development Services
                        </Typography>
                        <Grid container my={4} spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Stack
                                    component={Card}
                                    boxShadow={8}
                                    borderRadius={5}
                                    p={2}
                                    pt={4}
                                    minHeight={400}
                                    spacing={2}
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    textAlign="center"
                                >
                                    <Box component="img" src="/img/home/icons/rv.png" alt="rv" width={100} />
                                    <Typography fontSize={20} fontWeight="bold">
                                        RV parks
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 14, md: 18 }
                                        }}
                                    >
                                        RV Parks are BTD’s solution to the affordable housing problem. We are building the
                                        brand PointRV (PointRv.com) throughout the western United States. Generally, we hold
                                        these projects for cashflow.
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Stack
                                    component={Card}
                                    boxShadow={8}
                                    borderRadius={5}
                                    p={2}
                                    pt={4}
                                    minHeight={400}
                                    spacing={2}
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    textAlign="center"
                                >
                                    <Box
                                        component="img"
                                        src="/img/home/icons/selfstorage.png"
                                        alt="selfstorage"
                                        width={100}
                                    />
                                    <Typography fontSize={20} fontWeight="bold">
                                        Self-Storage
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 14, md: 18 }
                                        }}
                                    >
                                        The self-storage model BTD has had most success with includes a mix of climate
                                        control, traditional, and/or RV storage. Historically we have had successful exits.
                                        Generally, we operate and then sell these projects.
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Stack
                                    component={Card}
                                    boxShadow={8}
                                    borderRadius={5}
                                    p={2}
                                    pt={4}
                                    minHeight={400}
                                    spacing={2}
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    textAlign="center"
                                >
                                    <Box
                                        component="img"
                                        src="/img/home/icons/multifamily.png"
                                        alt="multifamily"
                                        width={100}
                                    />
                                    <Typography fontSize={20} fontWeight="bold">
                                        Multi-family
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 14, md: 18 }
                                        }}
                                    >
                                        BTD’s multi-family projects vary in size and location based on demand and pricing,
                                        This asset is generally sold.
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </Container>
    );
};
export default InfoSection;
