import { RVParksData } from '@src/shared/constants/Projects/RVParks';
import { StorageData } from '@src/shared/constants/Projects/Storage';
import { MultiFamilyData } from '@src/shared/constants/Projects/MultiFamily';
import { OfficeData } from '@src/shared/constants/Projects/Office';
import { OtherData } from '@src/shared/constants/Projects/Other';

export const ProjectsData = [
    {
        title: 'RV Parks',
        data: RVParksData
    },
    {
        title: 'Storage',
        data: StorageData
    },
    {
        title: 'Multi Family',
        data: MultiFamilyData
    },
    {
        title: 'Office',
        data: OfficeData
    },
    {
        title: 'Other',
        data: OtherData
    }
];
