import React from 'react';
import {CardMedia, Container, Typography, Grid, Link, Stack, Box} from '@mui/material';
import {NavbarSpace} from "@components/Utilities/Navbar";

const projectsData = [
    {
        avatar: '/img/team/team1.png',
        name: 'Jonathan Fink',
        linkedin: 'https://www.linkedin.com/in/jfink206'
    },
    {
        avatar: '/img/team/team2.png',
        name: 'Scott Stewart',
        linkedin: 'https://www.linkedin.com/in/scott-stewart-1229a7261/'
    },
    {
        avatar: '/img/team/team4.png',
        name: 'Paul Hilbig',
        linkedin: 'https://www.linkedin.com/in/paul-hilbig-b0247b5/'
    },
    {
        avatar: '/img/team/team5.png',
        name: 'Ken Firmage',
        linkedin: 'https://www.linkedin.com/in/kenneth-firmage-81918317/'
    },
    {
        avatar: '/img/team/team6.png',
        name: 'Mike Fife',
        linkedin: 'https://www.linkedin.com/in/michael-f-3429769/'
    },
    {
        avatar: '/img/team/team7.png',
        name: 'Jake Fink',
        linkedin: 'https://www.linkedin.com/in/jake-fink-86a075113/'
    }
];

const TeamSection: React.FunctionComponent = () => {
    return (
        <Container id="#team">
            <Stack alignItems='center' spacing={8}>
                <NavbarSpace />
                <Typography
                    fontWeight={600}
                    variant='h2'
                    color='gray'
                >
                    Team
                </Typography>
               <Box>
                   <Grid container spacing={2}>
                       {projectsData.map((project, index) => (
                           <Grid item xs={12} md={4} key={index}>
                               <Stack alignItems="center" justifyContent="center" textAlign="center">
                                   <CardMedia
                                       component="img"
                                       src={project.avatar}
                                       alt={project.name}
                                       sx={{ width: 250, height: 250, borderRadius: '50%' }}
                                   />
                                   <Stack
                                       component={Link}
                                       p={2}
                                       spacing={1}
                                       href={project.linkedin}
                                       style={{ textDecoration: 'none' }}
                                       target="_blank"
                                   >
                                       <Typography
                                           fontWeight="bold"
                                           sx={{
                                               fontSize: { xs: 16, md: 20 }
                                           }}
                                       >
                                           {project.name}
                                       </Typography>
                                   </Stack>
                               </Stack>
                           </Grid>
                       ))}
                   </Grid>
               </Box>
            </Stack>
        </Container>
    );
};
export default TeamSection;
