import React, { useMemo, useState } from 'react';
import { Box, Card, CardContent, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import EmailService from '@src/api/Emails/EmailCtrl';
import RoundedButton from '@components/Utilities/Buttons/RoundedButton';
import { AlertMessage, AlertType } from '@components/Utilities/AlertMessage';
import { contactEmail } from '@src/shared/constants';
import {NavbarSpace} from "@components/Utilities/Navbar";

const ContactSection: React.FunctionComponent = () => {
    const emailService = useMemo(() => new EmailService(), []);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [alert, setAlert] = useState<AlertType>({
        title: '',
        message: '',
        severity: 'success',
        open: false
    });
    const sendEmail = async (e: any) => {
        e.preventDefault();
        try {
            await emailService.sendEmail({
                to: contactEmail,
                message: {
                    subject: `Blue Terra Development Contact from ${name}`,
                    text: `email: ${email}, message: ${message}`
                }
            });
            setName('');
            setEmail('');
            setMessage('');
            setAlert({
                title: 'Success',
                message: 'Message send successfully',
                severity: 'success',
                open: true
            });
        } catch (error) {
            setAlert({
                title: 'Error',
                message: 'Something went wrong',
                severity: 'error',
                open: true
            });
        }
    };

    return (
        <Container id="#contact">
            <Stack alignItems='center' spacing={8} mb={8}>
                <NavbarSpace />
                <Typography
                    fontWeight={600}
                    variant='h2'
                    color='gray'
                >
                    Contact
                </Typography>
               <Box width='100%'>
                   <Grid container spacing={2}>
                       <Grid item xs={12} md={6} textAlign="center">
                           <iframe
                               src="https://maps.google.com/maps?q=2541%20E%20Gala%20St&t=&z=13&ie=UTF8&iwloc=&output=embed"
                               width="300"
                               height="300"
                               frameBorder="0"
                               allowFullScreen
                           />
                           <Typography variant="h4" fontWeight={500} gutterBottom>
                               Blue Terra Development
                           </Typography>
                       </Grid>
                       <Grid item xs={12} md={6}>
                           <Card elevation={8}>
                               <CardContent component="form" onSubmit={sendEmail}>
                                   <Stack direction="column" spacing={2}>
                                       <Typography variant="h4">Send us a message</Typography>
                                       <Typography variant="subtitle1">We will respond as soon as possible</Typography>
                                       <TextField
                                           required
                                           fullWidth
                                           id="name"
                                           label="Name"
                                           name="name"
                                           autoComplete="name"
                                           value={name}
                                           onChange={(e) => setName(e.target.value)}
                                       />
                                       <TextField
                                           required
                                           fullWidth
                                           id="email"
                                           label="Email address"
                                           name="email"
                                           autoComplete="email"
                                           value={email}
                                           onChange={(e) => setEmail(e.target.value)}
                                       />
                                       <TextField
                                           required
                                           multiline
                                           id="message"
                                           label="Message"
                                           name="message"
                                           rows={6}
                                           value={message}
                                           onChange={(e) => setMessage(e.target.value)}
                                       />
                                       <RoundedButton type="submit">Send Message</RoundedButton>
                                   </Stack>
                               </CardContent>
                           </Card>
                       </Grid>
                   </Grid>
               </Box>
                <AlertMessage alert={alert} handleClose={() => setAlert({ ...alert, open: false })} />
            </Stack>
        </Container>

    );
};
export default ContactSection;
