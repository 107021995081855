export const OtherData = [
    {
        image: '/img/projects/Other/Route44.jpeg',
        title: 'Route 44',
        location: 'Eagle, ID',
        units: 'N/A',
        status: 'Coming Soon'
    },
    {
        image: '/img/projects/Other/HyattPlace.jpeg',
        title: 'Hyatt Place',
        location: 'Scottsdale, AZ',
        units: '232 Rooms',
        status: 'Coming Soon'
    },
    {
        image: '/img/projects/Other/EpicShine1.jpeg',
        title: 'Epic Shine',
        location: 'Caldwell, ID',
        units: 'N/A',
        status: 'Coming Soon'
    },
    {
        image: '/img/projects/Other/EpicShine2.jpeg',
        title: 'Epic Shine',
        location: 'Twin Falls, ID',
        units: 'N/A',
        status: 'Coming Soon'
    },
    {
        image: '/img/projects/Other/EpicShine3.jpeg',
        title: 'Epic Shine',
        location: 'Meridian, ID',
        units: 'N/A',
        status: 'Coming Soon'
    },
    {
        image: '/img/projects/Other/EpicShine4.jpeg',
        title: 'Epic Shine',
        location: 'Eagle, ID',
        units: 'N/A',
        status: 'Coming Soon'
    }
];
