import { firestore } from '@src/firebase';

export interface EmailType {
    id?: string;
    to: string;
    message: {
        subject: string;
        text?: string;
        html?: string;
    };
}

export const MAILS_COLLECTION = 'mail';

export const emailsRef = firestore.collection(MAILS_COLLECTION);
