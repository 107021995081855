import { emailsRef, EmailType } from '@src/api/Emails/Email';

class EmailService {
    sendEmail = (email: EmailType): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                await emailsRef.add(email);
                resolve();
            } catch (error) {
                reject('Error sending email: ' + error);
            }
        });
    };
}

export default EmailService;
