import React, { useEffect, useState } from 'react';
import { AppBar, Box, Container, Toolbar, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from 'react-router-dom';
import { COLORS } from '@colors/index';
import NavbarButton from '@components/Utilities/NavbarButton';

const theme = createTheme();

const useStyles = makeStyles(() => ({
    toolbarHidden: {
        backgroundColor: 'transparent'
    },
    toolbar: {
        backgroundColor: 'white'
    },
    sectionDesktop: {
        display: 'none !important',
        [theme.breakpoints.up('md')]: {
            display: 'flex !important'
        }
    },
    sectionMobile: {
        display: 'flex !important',
        [theme.breakpoints.up('md')]: {
            display: 'none !important'
        }
    },
    button: {
        textDecoration: 'none',
        color: 'black',
        '&:hover': {
            color: COLORS.Blue
        },
        '&.active': {
            color: COLORS.Blue,
            fontWeight: 'bold !important'
        }
    }
}));

interface NavbarProps {
    handleDrawerToggle: () => void;
}

export const NavbarSpace = () => {
    const classes = useStyles();
    return (
        <Box>
            <Toolbar className={classes.toolbarHidden} />
        </Box>
    );
};

const Navbar: React.FunctionComponent<NavbarProps> = (props) => {
    const { handleDrawerToggle } = props;
    const classes = useStyles();
    const logoHeight = 90;

    return (
        <Box>
            <AppBar position="fixed">
                <Toolbar disableGutters className={classes.toolbar}>
                    <Container>
                        <Grid container alignItems="center" mt={1}>
                            <Grid item xs={6}>
                                <Box component={NavLink} to="/">
                                    <Box component="img" src="/img/logos/logo.png" alt="logo" height={logoHeight} />
                                </Box>
                            </Grid>
                            <Grid item xs={6} className={classes.sectionDesktop}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    spacing={2}
                                >
                                    <Grid item>
                                        <NavbarButton to="/">Home</NavbarButton>
                                    </Grid>
                                    <Grid item>
                                        <NavbarButton to="/#projects">Projects</NavbarButton>
                                    </Grid>
                                    <Grid item>
                                        <NavbarButton to="/#team">Team</NavbarButton>
                                    </Grid>
                                    <Grid item>
                                        <NavbarButton to="/#contact">Contact</NavbarButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} className={classes.sectionMobile}>
                                <Grid container alignItems="center" justifyContent="flex-end">
                                    <Grid item>
                                        <IconButton onClick={handleDrawerToggle}>
                                            <MenuIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Navbar;
