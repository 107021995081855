import { useEffect, useState } from 'react';

const useImagesLoaded = () => {
    const [ref, setRef] = useState<any | undefined>(undefined);
    const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);

    useEffect(() => {
        const resolveReference: any = [];
        const imageElements = (ref && ref.getElementsByTagName('img')) || [];
        if (imageElements.length == 0) {
            setImagesLoaded(true);
        }
        const promisesArray = [...imageElements].map((img) => {
            if (!img.complete) {
                return new Promise((resolve) => {
                    resolveReference.push(resolve);
                    img.addEventListener('load', resolve, { once: true });
                });
            } else return null;
        });
        if (promisesArray.length > 0) {
            Promise.all(promisesArray).then(() => {
                setImagesLoaded(true);
            });
        }
        return () => {
            imageElements.forEach &&
                imageElements.forEach((img: any, index: number) => {
                    img.removeEventListener('load', resolveReference[index]);
                });
        };
    }, [ref]);

    return [setRef, imagesLoaded];
};

export default useImagesLoaded;
