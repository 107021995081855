export const RVParksData = [
    {
        image: '/img/projects/RVParks/CenterPoint.jpeg',
        title: 'Center Point',
        location: 'Nampa, ID',
        units: '126 Sites',
        status: 'Opened'
    },
    {
        image: '/img/projects/RVParks/RiverPoint.jpeg',
        title: 'River Point',
        location: 'Ontario, OR',
        units: '179 Sites',
        status: 'Opened'
    },
    {
        image: '/img/projects/RVParks/SunCreekPoint.png',
        title: 'SunCreek Point ',
        location: 'Caldwell, ID',
        units: '225 Sites',
        status: 'Break Ground: October 2022'
    },
    {
        image: '/img/projects/RVParks/CedarPoint.png',
        title: 'Cedar Point ',
        location: 'Cedar City, UT',
        units: '193 Sites',
        status: 'Break Ground: August 2023'
    },
    {
        image: '/img/projects/RVParks/MountainPoint.png',
        title: 'Mountain Point',
        location: 'Mountain Home, ID',
        units: '165 Sites',
        status: 'Break Ground: August 2023 '
    },
    {
        image: '/img/projects/RVParks/OvertonPoint.jpg',
        title: 'Overton Point',
        location: 'Overton, NV',
        units: '98 Sites',
        status: 'Break Ground: December 2023 '
    },
    {
        image: '/img/projects/RVParks/PoderosaPoint.png',
        title: 'Poderosa Point',
        location: 'Burley, ID',
        units: '160 Sites',
        status: 'Break Ground: Spring 2023'
    }
];
