import React, { useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Navbar from '@components/Utilities/Navbar';
import CustomDrawer from '@components/Utilities/CustomDrawer';
import Footer from '@components/Utilities/Footer';
const LandingPage: React.FunctionComponent = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <Box>
            <CssBaseline />
            <Navbar handleDrawerToggle={handleDrawerToggle} />
            <CustomDrawer drawerOpen={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
            <Outlet />
            <Footer />
        </Box>
    );
};
export default LandingPage;
