import React, { useEffect, useState } from 'react';
import { Stack, Fade } from '@mui/material';
import useImagesLoaded from '@src/hooks/useImagesLoaded';
import Loader from '@components/Utilities/Loader';
import InfoSection from '@components/Home/InfoSection';
import ProjectsSection from '@components/Home/ProjectsSection';
import TeamSection from '@components/Home/TeamSection';
import ContactSection from '@components/Home/ContactSection';
import BackgroundImage from "@components/Home/BackgroundImage";
import {NavbarSpace} from "@components/Utilities/Navbar";

const Home: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ref, loaded] = useImagesLoaded() as [any, boolean];

    useEffect(() => {
        if (loaded) {
            setTimeout(() => {
                setIsLoading(true);
            }, 1000);
        } else {
            setIsLoading(false);
        }
    }, [loaded]);

    return (
        <Stack minHeight="100vh" ref={ref}>
            <Fade in={!isLoading} appear={false} timeout={1000}>
                <div>
                    <Loader />
                </div>
            </Fade>
            <BackgroundImage />
            <InfoSection />
            <ProjectsSection />
            <TeamSection />
            <ContactSection />
        </Stack>
    );
};
export default Home;
