import React from 'react';
import { Grid } from '@mui/material';
import Lottie from 'react-lottie';
import * as animationData from './earth.json';

const Loader: React.FunctionComponent = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData
    };

    return (
        <Grid
            display="flex"
            flex={1}
            position="fixed"
            top={0}
            width="100%"
            height="100%"
            bgcolor="white"
            alignItems="center"
            justifyContent="center"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <Lottie options={defaultOptions} height={600} width={600} />
        </Grid>
    );
};

export default Loader;
