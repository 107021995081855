export const MultiFamilyData = [
    {
        image: '/img/projects/MultiFamily/Latitude40.jpeg',
        title: 'Latitude 40',
        location: 'Boise, ID',
        units: '40',
        status: 'Opened'
    },
    {
        image: '/img/projects/MultiFamily/Latitude42.jpeg',
        title: 'Latitude 42',
        location: 'Twin Falls, ID',
        units: '130',
        status: 'Opened'
    },
    {
        image: '/img/projects/MultiFamily/Latitude96.png',
        title: 'Latitude 96',
        location: 'Caldwell, ID',
        units: '96',
        status: 'Coming Soon'
    }
];
