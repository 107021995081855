import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyA3WMs34VuDEsxLmWbzraOlG6ynOgHUoPw',
    authDomain: 'blueterradevelopment.firebaseapp.com',
    projectId: 'blueterradevelopment',
    storageBucket: 'blueterradevelopment.appspot.com',
    messagingSenderId: '823093858912',
    appId: '1:823093858912:web:5af148a5d09a9dbd7c6905',
    measurementId: 'G-C1HQH54CJC'
};

firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore();
