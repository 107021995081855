import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { COLORS } from '@colors/index';
import { NavLink } from 'react-router-dom';
import NavbarButton from "@components/Utilities/NavbarButton";

const useStyles = makeStyles(() => ({
    footer: {
        backgroundColor: COLORS.LightGray
    },
    button: {
        textDecoration: 'none',
        color: 'black',
        '&:hover': {
            color: COLORS.Blue
        },
        '&.active': {
            color: COLORS.Blue,
            fontWeight: 'bold !important'
        }
    }
}));

const Footer: React.FunctionComponent = (props) => {
    const classes = useStyles();

    return (
        <Box component="footer" p={4} bgcolor={COLORS.LightGray}>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box
                            component={NavLink}
                            to="/"
                            sx={{
                                display: 'flex',
                                justifyContent: { xs: 'center', md: 'flex-start' }
                            }}
                        >
                            <Box component="img" src="/img/logos/logo.png" alt="logo" height={80} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} display="flex">
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            sx={{ justifyContent: { xs: 'center', md: 'flex-end' } }}
                        >
                            <Grid item>
                                <NavbarButton to="/">
                                    Home
                                </NavbarButton>
                            </Grid>
                            <Grid item>
                                <NavbarButton to="/#projects">
                                    Projects
                                </NavbarButton>
                            </Grid>
                            <Grid item>
                                <NavbarButton to="/#team">
                                    Team
                                </NavbarButton>
                            </Grid>
                            <Grid item>
                                <NavbarButton to="/#contact">
                                    Contact
                                </NavbarButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" color={COLORS.LightBlack} align="center">
                            {`Copyright © ${new Date().getFullYear()}. Blue Terra Development`}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;
