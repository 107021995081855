export const StorageData = [
    {
        image: '/img/projects/Storage/CityGate.jpeg',
        title: 'City Gate',
        location: 'Gilbert, AZ',
        units: '683',
        status: 'Opened'
    },
    {
        image: '/img/projects/Storage/CanyonGate.png',
        title: 'Canyon Gate',
        location: 'Jerome, ID',
        units: 'N/A',
        status: 'Coming: Early 2023'
    },
    {
        image: '/img/projects/Storage/PonderosaStorage.png',
        title: 'Ponderosa Storage',
        location: 'Burley, ID',
        units: 'N/A',
        status: 'Break Ground: Spring 2023'
    },
    {
        image: '/img/projects/Storage/PeoriaStorage.png',
        title: 'Peoria Storage',
        location: 'Peoria, AZ',
        units: 'N/A',
        status: 'Coming Soon'
    }
];
