import React from 'react';
import {Box} from "@mui/material";
const BackgroundImage: React.FunctionComponent = () => {
   return (
       <Box
           sx={{
               backgroundImage: 'url(/img/home/backgroundHome.jpg)',
               backgroundRepeat: 'repeat',
               backgroundSize: 'cover',
               backgroundPosition: 'center',
               position: 'fixed',
               height: '100vh',
               width: '100vw',
               opacity: 0.5,
               zIndex: -1
           }}
       />
   )
};
export default BackgroundImage;
