export const OfficeData = [
    {
        image: '/img/projects/Office/RedHawk.jpeg',
        title: 'Red Hawk',
        location: 'Kuna, ID',
        units: '6',
        status: 'Opened: Fall 2021'
    },
    {
        image: '/img/projects/Office/BellTower.png',
        title: 'Bell Tower',
        location: 'Meridian, ID',
        units: 'N/A',
        status: 'Break Ground: November 2022'
    },
    {
        image: '/img/projects/Office/SkyRanch.png',
        title: 'Sky Ranch Medical',
        location: 'Calwell, ID',
        units: 'N/A',
        status: 'Break Ground: October 2022'
    },
    {
        image: '/img/projects/Office/ActiveFamily.png',
        title: 'Active Family Chiropractic',
        location: 'Meridian, ID',
        units: 'N/A',
        status: 'Coming Soon'
    }
];
